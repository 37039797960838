.footer {
    border-top: 1px solid black;
    margin: 2rem 0 1.25rem 0;
}

.footer p {
    line-height: 1.55rem;
    margin: 2rem 0 1.25rem 0;
}

.footer a {
    color: #f71919;
    text-decoration: none;
    /* text-decoration-color: #f71919; */
    /* text-decoration-color: green; */
}

.footer a:hover {
    text-decoration-color: #f71919;
    text-decoration: underline;
}