.jsonExample {
    margin: 0 0 1.15rem 0;
}

.desc {
    line-height: 1.15rem;
    margin: 0.25rem 0 0 0;
}

.loader {
    width: 1.15rem;
    height: 1.15rem;
    border: 3px solid #000;
    border-bottom-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    margin: 1.05rem 0 0 0;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

.loadButton {
    margin: 1.05rem 0 0 0;
    padding: 1rem;
    font-size: 1.15rem;
    border: 1px solid black;
    color: #000;
    cursor: pointer;
}

.loadButton:disabled {
    opacity: 20%;
    cursor: default;
}