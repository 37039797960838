.example {
    margin: 0 0 1.15rem 0;   
}

.heading {
    color: #3DB332;
    margin: 0 0 0 0;
}

.desc {
    line-height: 1.15rem;
    margin: 0.25rem 0 0 0;
}