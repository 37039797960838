.content {
    color: #000;
    font-family: 'Anonymous Pro', Courier, sans-serif;
}

.snippet {
    background: transparent;
    margin: 0.85rem 0 0 0;
    padding: 1.25rem 1.5rem;
    /* overflow-x: scroll; */
    border: 1px solid #A9B3A8;
}

/* .snippet code.request {
    font-family: 'Anonymous Pro';
} */

/* code.request.get {
    color: black;
    content: 'GET'; 
    font-size: 24px;
    color: #000;
    font-family: 'Anonymous Pro', Courier, sans-serif;
} */