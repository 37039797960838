.nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
}

.nav h1, .nav ul {
    margin: 2rem 0 1.25rem 0;
}

.nav li {
    margin: 0 0.85rem;
}