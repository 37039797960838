.home {
    flex-direction: column;
    width: 296px;
    border: none;
}

.about p {
    line-height: 1.55rem;
    margin: 0 0 1.25rem 0;
}

.about a {
    color: #f71919;
    text-decoration: none;
}

.about a:hover {
    text-decoration-color: #f71919;
    text-decoration: underline;
}

@media only screen and (min-width: 500px) {
    .home {
        width: calc(296px + 10.25vw);
    }
}