.usage {
    margin: 2rem 0 1.25rem 0;
}

.request {
    overflow-x: scroll;
}

.response {
    overflow: auto;
}

/* .response {
    background: transparent;
    margin: 0.85rem 0 0 0;
    padding: 1.25rem 1.5rem;
    border: 1px solid #A9B3A8;
} */

.get::before {
    color: #3DB332;
    content: 'GET ';
}